<template>
    <div class="login-box">
      <div style="color:red;font-size:30px;padding-bottom:80px"> 
        佳艺麻辣烫后台管理系统 
      </div>
      <div class="center-box">
          <el-input class="input"
              placeholder="请输入账号"
              v-model="account" clearable>
          </el-input>
          <el-input placeholder="请输入密码" v-model="password" show-password class="input"></el-input>
        <p style="color:red;font-size:20px;margin-left:6px;">{{errormsg}}</p>
          <el-button type="primary"  class="button" @click="clickLogin">登录</el-button>
          
      </div>
      <div class="icp" >
        <img src="/beian.png" style="width:20px;border-radius:50%"/>&#32
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=37040402020052" rel="noreferrer" target="_blank">鲁公网安备37040402020052号</a>
        &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp
        <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备2024111858号</a>
      </div>
    </div>
</template>

<script>
import { login } from "@/api/apis"; //  @/相当于直接切换到src的根目录
export default {
  data() {
    return {
      account: "",
      password: "",
      errormsg: "" //错误提示
    };
  },
  methods: {
    clickLogin() {

      login(this.account, this.password).then(res => {
        res = res.data;
        console.log('res', res);
        if (res.status == true) {
            //写入token
            localStorage.token=res.data.token
            //写入role
            localStorage.role=res.data.role
            //存入用用户名
            localStorage.account=this.account
            //存入id
            localStorage.id=res.data.id
            // localStorage.setItem("token",res.data.token)
          //成功
          //this.$router指向main.js中注入的router对象实例
          this.$router.push("/main/index"); //改变hash地址
          this.$message({
            message: "恭喜你，登录成功",
            type: "success"
          });
        } else {
          this.errormsg = res.msg;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.login-box {
  height: 100%;
  width: 100%;
  background: #545c64;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../../public/980.jpg") no-repeat center;
  background-size: 100% 100%;
}
.center-box {
  width: 300px;
  height: 200px;
  border-radius: 15px;
  background-color: #fff;
  padding: 30px;
  padding-top: 10px;
}

.input {
  margin: 5px;
  margin-top: 20px;
}
.button {
  margin-top: 10px;
  width: 100%;
  padding-left: 10px;
  margin-left: 4px;
  clear: both;
}

.icp {
  color: gray;
  font-size: 20px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icp > a {
    color: aqua;
    text-decoration: none;
}
.icp > a:hover {
    color: white;
    text-decoration: none;
}

</style>